import * as R from 'ramda'
import cx from 'classnames'
import { BILLING_TERM_TEXT_BY_BILLING_PERIOD_IN_MONTHS } from 'constants/products'
import {
  getDailyPrice,
  getDiscountedPrice,
  getMonthlyPrice,
  getPriceInDollars,
  getTrialDaysDuration,
} from 'utilities/stripe'
import s from './styles.module.css'
import PlanDurationSelection from '../plan-duration-selection'
import { useEffect, useState } from 'react'
import { billingPeriodInWords } from '../../../helpers'
import { formatCurrency } from 'utilities/numbers'
import LandingPagePlanCardExperiment from 'components/homepage/experiment/homepage-upgrade/pricing/landing-page-plan-card'
import LandingPagePlanCard from '../landing-page-plan-card'
import ProgramCard from 'components/payment/common/program-card'
import LiveWorkoutSelection from '../live-workout-selection'
import { useExperimentSplit } from 'utilities/experiment/context'

const BILLING_PERIOD_IN_MONTHS = 1

interface IPlanSelectionProps {
  products: any[]
  options?: {
    overridePriceDescription?: string
    overrideDailyPrice?: number
  }
  landingPageOptions?: any
  isHomepageUpgradeExperiment?: boolean
  product: any
  coupon: any
  trialDays: number
  showPlanDuration?: boolean
  onSelection?: (product: any, price: any) => void
  billingPeriodInMonths?: number
  className?: string
  isLandingPage?: boolean
  isCoachPage?: boolean
  loading?: boolean
  showLiveOption?: boolean
  price: any
  coach?: any
}

interface IPlanSelectionProps {
  products: any[]
  options?: {
    overridePriceDescription?: string
    overrideDailyPrice?: number
  }
  landingPageOptions?: any
  product: any
  coupon: any
  trialDays: number
  showPlanDuration?: boolean
  onSelection?: (product: any, price: any) => void
  billingPeriodInMonths?: number
  className?: string
  isLandingPage?: boolean
  isCoachPage?: boolean
  loading?: boolean
  showLiveOption?: boolean
  price: any
  coach?: any
}

const PlanSelection = ({
  products,
  options = {
    overridePriceDescription: undefined,
    overrideDailyPrice: undefined,
  },
  landingPageOptions,
  product,
  coupon,
  trialDays,
  showPlanDuration = false,
  onSelection,
  billingPeriodInMonths: billingPeriodInMonthsProp = BILLING_PERIOD_IN_MONTHS,
  className = s.planSelectionContainer,
  isLandingPage = false,
  isHomepageUpgradeExperiment = false,
  isCoachPage = false,
  loading = false,
  showLiveOption = false,
  price: selectedPrice,
  coach = null,
}: IPlanSelectionProps) => {
  const livePush = useExperimentSplit('livePush')

  const isFreeTrial = !!trialDays

  const [billingPeriodInMonths, setBillingPeriodInMonths] = useState(
    billingPeriodInMonthsProp
  )

  const handleDurationSelection = months => {
    if (showPlanDuration) setBillingPeriodInMonths(months)
  }

  useEffect(() => {
    if (!isLandingPage) {
      setBillingPeriodInMonths(billingPeriodInMonthsProp)
    }
  }, [billingPeriodInMonthsProp, isLandingPage])

  const LandingPagePlanCardComponent = isHomepageUpgradeExperiment
    ? LandingPagePlanCardExperiment
    : LandingPagePlanCard

  return (
    <div className={cx(className)}>
      {showPlanDuration && (
        <div className={s.planSelection}>
          <PlanDurationSelection
            duration={billingPeriodInMonths}
            onChange={handleDurationSelection}
          />
        </div>
      )}
      <div className={s.plans}>
        {R.map(p => {
          const firstMonthPrice = R.find(
            R.propEq('billingPeriodInMonths', 1),
            p.prices
          )

          const firstMonthPriceInDollars =
            getDiscountedPrice({
              price: firstMonthPrice,
              coupon,
              isFirstTerm: true,
            }) / 100

          const [firstMonthPriceInDollarsWithoutCoupon] = getPriceInDollars({
            price: firstMonthPrice,
          })

          const formattedFirstMonthPriceInDollars =
            firstMonthPriceInDollars % 1 === 0
              ? formatCurrency(firstMonthPriceInDollars, {
                  minimumFractionDigits: 0,
                })
              : formatCurrency(firstMonthPriceInDollars, {
                  minimumFractionDigits: 2,
                })

          const price = R.find(
            R.propEq('billingPeriodInMonths', billingPeriodInMonths),
            p.prices
          )
          const [dailyPriceInDollars] = getDailyPrice({
            price,
            coupon,
            isFree: isFreeTrial,
            shouldRound: !coupon,
          })
          const [dailyPriceInDollarsWithOutCoupon] = getDailyPrice({
            price,
            shouldRound: !coupon,
          })

          const [baseMonthlyPriceInDollars] = getMonthlyPrice({
            price,
            coupon,
            isFree: isFreeTrial,
            shouldRound: !coupon,
          })

          const firstTermPriceInDollars = Math.round(
            getDiscountedPrice({
              price,
              coupon,
              isFirstTerm: true,
            }) / 100
          )
          const secondTermPriceInDollars = Math.round(
            getDiscountedPrice({
              price,
              coupon,
              isFirstTerm: false,
            }) / 100
          )
          const isOneTermDiscount =
            firstTermPriceInDollars !== secondTermPriceInDollars

          const billingTermText =
            BILLING_TERM_TEXT_BY_BILLING_PERIOD_IN_MONTHS[
              price.billingPeriodInMonths
            ]
          const priceDescriptionSubtext = `$${secondTermPriceInDollars} billed ${
            price.billingPeriodInMonths === 6 ? 'every' : ''
          } ${billingPeriodInWords(price.billingPeriodInMonths)}`

          const pricingDescription = isOneTermDiscount
            ? `First ${
                isFreeTrial
                  ? `${getTrialDaysDuration(trialDays)} free`
                  : coupon
                  ? `month only ${formattedFirstMonthPriceInDollars}`
                  : `${billingTermText} only $${firstTermPriceInDollars}`
              } - then ${
                // this feels messy. do we need a different condition for guest pass?
                isFreeTrial && trialDays < 30
                  ? `$${
                      dailyPriceInDollars === 0
                        ? dailyPriceInDollarsWithOutCoupon
                        : dailyPriceInDollars
                    }/day${
                      isLandingPage ? '' : ` (${priceDescriptionSubtext})`
                    }`
                  : `${priceDescriptionSubtext}`
              }`
            : options.overridePriceDescription ?? `${priceDescriptionSubtext}`

          return (
            <div key={p.id} className={s.planWrapper}>
              {isLandingPage ? (
                <LandingPagePlanCardComponent
                  products={products}
                  options={options}
                  landingPageOptions={landingPageOptions}
                  coupon={coupon}
                  billingPeriodInMonths={billingPeriodInMonths}
                  dailyPrice={dailyPriceInDollars}
                  dailyPriceWithoutCoupon={dailyPriceInDollarsWithOutCoupon}
                  pricingDescription={pricingDescription}
                  isLandingPage={isLandingPage}
                  isCoachPage={isCoachPage}
                  onSelection={() => onSelection(p, price)}
                  loading={loading}
                  isFreeTrial={isFreeTrial}
                  firstMonthPriceUnitAmount={firstMonthPrice?.unitAmount}
                />
              ) : (
                <div className={s.programCardWrapper}>
                  <ProgramCard
                    price={selectedPrice}
                    coupon={coupon}
                    billingPeriodInMonths={billingPeriodInMonths}
                    product={product}
                    baseProduct={p}
                    basePrice={firstMonthPriceInDollarsWithoutCoupon}
                    baseMonthlyPriceInDollars={baseMonthlyPriceInDollars}
                    isFreeTrial={isFreeTrial}
                    coach={coach}
                    showingLiveOptions={showLiveOption}
                  />
                </div>
              )}
              {!isLandingPage &&
                !isFreeTrial &&
                showLiveOption &&
                livePush === 'A' && (
                  <div className={s.programCardWrapper}>
                    <LiveWorkoutSelection
                      products={products}
                      product={product}
                      coupon={coupon}
                      billingPeriodInMonths={billingPeriodInMonths}
                      onSelection={onSelection}
                    />
                  </div>
                )}
            </div>
          )
        }, R.take(1, products))}
      </div>
    </div>
  )
}

export default PlanSelection
